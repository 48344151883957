import React from 'react';
import './App.css';
import { useEthers } from '@usedapp/core'

import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';

import FiresaleNavBar from './components/FiresaleNavBar';
import Home from './layouts/Home'
import About from './layouts/About'
import Selection from './layouts/Selection';
import { networks } from './lib/networks';
import { isDevelopment } from './lib/constants';
import Whitelist from './layouts/Whitelist';
import Vault from './layouts/Vault';

function App() {
  // See types: https://github.com/NoahZinsmeister/web3-react/tree/v6/docs#overview
  const { activateBrowserWallet, account, deactivate } = useEthers()

  const addresses = isDevelopment ? networks.goerli : networks.mainnet;
  return (
    <div className="App">
      <Router>
        <FiresaleNavBar
          account={account}
          onDisconnectClick={() => deactivate()}
        />
        <Routes>
          {account
            ? (
              <Route
                path="/"
                element={
                  <Selection
                    account={account}
                    addresses={addresses}
                  />
                }
              />
            )
            : (
              <Route
                path="/"
                element={<Home onConnectClick={() => activateBrowserWallet()} />}
              />
            )
          }
          <Route path="/whitelist" element={<Whitelist/>}/>
          <Route path="/vault" element={<Vault/>}/>
          <Route path="/about" element={<About/>} />
        </Routes>

      </Router>
    </div>
  );
}

export default App;
