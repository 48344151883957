import { formatEther } from "@ethersproject/units";

export function truncateAddress(address: string) {
  if (address.substring(0, 2) !== "0x") {
    return address;
  }

  var start = address.substring(0, 6);
  var end = address.substring(address.length - 5);
  return start + "..." + end;
}

export function calculateTaxSavings(openseaItem: any, taxRate: number) {
  let taxSavings = 0;
  if (openseaItem?.last_sale) {
    const price = formatEther(openseaItem?.last_sale?.total_price);
    const usdToEth = Number(openseaItem?.last_sale.payment_token.usd_price);
    taxSavings = Number(price) * usdToEth * taxRate;
  }
  return currencyFormatter.format(taxSavings);
}

export function lastSalePrice(openseaItem: any) {
  let priceInUSD = 0;
  if (openseaItem?.last_sale) {
    const price = formatEther(openseaItem?.last_sale?.total_price);
    const usdToEth = Number(openseaItem?.last_sale.payment_token.usd_price);
    priceInUSD = Number(price) * usdToEth;
  }
  return currencyFormatter.format(priceInUSD);
}

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const formatCurrency = (amount: number) => {
  return currencyFormatter.format(amount);
};

// groups array of items by key
export function groupBy(array: any[], key: string): any[][] {
  return Array.from(
    array
      .reduce((m, o) => m.set(o[key], [...(m.get(o[key]) || []), o]), new Map())
      .values()
  );
}
