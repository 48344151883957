export const networks = {
  mainnet: {
    firesale_address: "0xbBC61c3799C9CCB64493f9661901841DdB672D8e",
    lit_token_address: "0x4fAd83647786Da7A6075Be67b48ee42072192d98"
  },
  kovan: {
    firesale_address: "0x5EE7CD9F2E408aEc4a6e74C5C2D1D21a623e8630",
    lit_token_address: "tbd"
  },
  goerli: {
    firesale_address: "0x2A4FBc0fccc52D0dfA9B17AaC467d2F30E00ae21",
    lit_token_address: "0xD5332C758dD501bCa9c98e685cb2B6eb3e684103"
  },
};


// first goerli implementation
// firesale_address: "0x2A4FBc0fccc52D0dfA9B17AaC467d2F30E00ae21",
// lit_token_address: "0xD5332C758dD501bCa9c98e685cb2B6eb3e684103"

export const testWalletNfts = [
  {
    id: 124,
    tokenId: 419407,
    imageUrl:
      "https://lh3.googleusercontent.com/dz96VNt3vZ8ey9nxW8DR-Bz78Qn_CO98mzMcx0ZEw0uHS3avjKSpxmK6y-dW7PRr_qZdXGQsa2VBmOxMcKs-PycrF8Uuwvt7PXQa",
    name: "Multi Faucet NFT 419407",
    taxSavings: "$100",
    selected: false,
    assetContract: "0xf5de760f2e916647fd766B4AD9E85ff943cE3A2b",
    collectionName: "SHIBA",
    openseaLink: "https://opensea.io/assets/0xf7c305b6221c2641bd12e9c2107c47af5f17a31e/1",
    lastSalePrice: "0.00",
    formattedLastSale: "N/A",
    collectionImageUrl: "https://lh3.googleusercontent.com/dz96VNt3vZ8ey9nxW8DR-Bz78Qn_CO98mzMcx0ZEw0uHS3avjKSpxmK6y-dW7PRr_qZdXGQsa2VBmOxMcKs-PycrF8Uuwvt7PXQa"
  },
  {
    id: 125,
    tokenId: 419406,
    imageUrl:
      "https://lh3.googleusercontent.com/dz96VNt3vZ8ey9nxW8DR-Bz78Qn_CO98mzMcx0ZEw0uHS3avjKSpxmK6y-dW7PRr_qZdXGQsa2VBmOxMcKs-PycrF8Uuwvt7PXQa",
    name: "Multi Faucet NFT 419406",
    taxSavings: "$100",
    selected: false,
    assetContract: "0xf5de760f2e916647fd766B4AD9E85ff943cE3A2b",collectionName: "SHIBA",
    lastSalePrice: "0.00",
    formattedLastSale: "N/A",
    openseaLink: "https://opensea.io/assets/0xf7c305b6221c2641bd12e9c2107c47af5f17a31e/1",
    collectionImageUrl: "https://lh3.googleusercontent.com/dz96VNt3vZ8ey9nxW8DR-Bz78Qn_CO98mzMcx0ZEw0uHS3avjKSpxmK6y-dW7PRr_qZdXGQsa2VBmOxMcKs-PycrF8Uuwvt7PXQa"
  },
  {
    id: 126,
    tokenId: 419220,
    imageUrl:
      "https://lh3.googleusercontent.com/dz96VNt3vZ8ey9nxW8DR-Bz78Qn_CO98mzMcx0ZEw0uHS3avjKSpxmK6y-dW7PRr_qZdXGQsa2VBmOxMcKs-PycrF8Uuwvt7PXQa",
    name: "Multi Faucet NFT 419220",
    taxSavings: "$100",
    selected: false,
    assetContract: "0xf5de760f2e916647fd766B4AD9E85ff943cE3A2b",
    collectionName: "SHIBA",
    lastSalePrice: "0.00",
    formattedLastSale: "N/A",
    openseaLink: "https://opensea.io/assets/0xf7c305b6221c2641bd12e9c2107c47af5f17a31e/1",
    collectionImageUrl: "https://lh3.googleusercontent.com/dz96VNt3vZ8ey9nxW8DR-Bz78Qn_CO98mzMcx0ZEw0uHS3avjKSpxmK6y-dW7PRr_qZdXGQsa2VBmOxMcKs-PycrF8Uuwvt7PXQa"
  }
];
