import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Colors from '../lib/colors'
import { isLive } from '../lib/constants'
import Footer from '../components/Footer';

type Props = {
  onConnectClick: any
}

export default function Home({
  onConnectClick
}: Props) {
  return (
    <Container fluid>
      <Row style={{marginTop: '25vh'}}>
        <Col className="text-headline">
          Don't just burn your NFTs, get $LIT
        </Col>
      </Row>
      <Row style={{marginTop: '5vh'}}>
        {isLive
          ? (
            <button
              onClick={onConnectClick}
              className="connect-wallet"
              style={{backgroundColor: Colors.blue}}
            >
              Connect Wallet
            </button>
          )
          : (<Col>
          <div style={{padding: "24px"}}>Coming soon!</div>
          <a href="/whitelist">
          <button
              className="btn btn-secondary"
              style={{backgroundColor: Colors.blue}}
            >
              Can you swap your NFT for $LIT?
            </button>
          </a>
          </Col>)
        }
      </Row>
      <Footer />
    </Container>
  )
}
