import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Colors from "../lib/colors";
import { isLive } from "../lib/constants";
import Footer from "../components/Footer";
import { Alert, Button, Form, ProgressBar } from "react-bootstrap";
import { useEffect, useState } from "react";
import { readRemoteFile } from "react-papaparse";
import { getWhitelist } from "../lib/parse";

type ParseResult = {
  data: Array<Project>;
};

type Project = {
  CONTRACT_ADDRESS: string;
  PROJECT_NAME: string;
};

export default function Whitelist() {
  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [project, setProject] = useState<Project>();
  const [whitelistAddresses, setWhitelistAddresses] = useState<ParseResult>();

  useEffect(() => {
    getWhitelist(setWhitelistAddresses);
  }, []);

  const checkIfWhitelisted = (address: string) => {
    let index = whitelistAddresses.data.findIndex((x) => {
      return address == x.CONTRACT_ADDRESS;
    });
    if (index > 0) {
      setProject(whitelistAddresses.data[index]);
      return true;
    } else {
      return false;
    }
  };

  const onContractAddressChange = (e) => {
    if (e.target.value.length > 1) {
      let result = checkIfWhitelisted(e.target.value);

      if (result) {
        setIsWhitelisted(true);
      } else {
        setIsWhitelisted(false);
      }
    } else {
      setIsWhitelisted(false);
    }
  };

  return (
    <Container fluid>
      <Row
        className="justify-content-md-center"
        style={{ padding: "20px", marginTop: "25vh" }}
      >
        <Col md="4" className="text-headline">
          <h2>Check if whitelisted</h2>
          <h5>Enter the contract address for a NFT project</h5>
          <Form>
            <Form.Control
              type="text"
              placeholder="Enter a contract address"
              onChange={onContractAddressChange}
            />
            <Form.Text>
              {isWhitelisted
                ? "This is a whitelisted contract 😍"
                : "Not whitelisted 🙅‍♀️"}
              <ProgressBar now={isWhitelisted ? 100 : 0} variant="success" />
            </Form.Text>
          </Form>

          <Alert style={{ fontSize: "12px" }} variant="info">
            whitelist was based off of projects with 10+ sales events
            ex. 0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d (Bored Apes Yacht Club)
          </Alert>
        </Col>
      </Row>

      <Footer />
    </Container>
  );
}
