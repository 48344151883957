import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  ListGroup,
  Image,
  Container,
  Spinner,
  Button,
} from "react-bootstrap";
import Colors from "../lib/colors";
import { groupBy } from "../lib/utils";
import { AddressBook, NFTData } from "../types";
import ButtonRound from "./ButtonRound";
import { ethers } from "ethers";
import litTokenAbi from "../lib/contracts/LITToken.json";
import { useEthers } from "@usedapp/core";
import { formatEther } from "@ethersproject/units";

type Props = {
  selectedItems: NFTData[];
  addresses: AddressBook;
  isBatchApproved: boolean;
  litBalance: string;
  isSwapPending: boolean;
  onSwapClick: () => any;
  onApprovalClick: () => any;
  refreshApprovalStatus: ()=> any;
};

export default function CheckoutWidget({
  selectedItems,
  addresses,
  litBalance,
  isSwapPending,
  isBatchApproved,
  onApprovalClick,
  onSwapClick,
  refreshApprovalStatus,
}: Props) {
  const [itemGroups, setItemGroups] = useState<NFTData[][]>();
  const [estimatedLitAmount, setEstimatedLitAmount] = useState<string>();

  const { library } = useEthers();

  // TODO: Estimated amount of $LIT user will receive. Remove if
  // information not available
  const amount = "???";

  useEffect(() => {
    const grouped: NFTData[][] = groupBy(selectedItems, "assetContract");
    setItemGroups(grouped);
  }, [selectedItems]);

  useEffect(() => {
    const updateRate = async () => {
      const LITToken = new ethers.Contract(
        addresses.lit_token_address,
        litTokenAbi,
        library
      );
      let rate = await LITToken.getRate();
      let litAmount = 0
      if(selectedItems.length > 0) litAmount = rate.mul(selectedItems.length);
      let formattedEther = formatEther(litAmount.toString());
      setEstimatedLitAmount("~" + formattedEther);
    };
    updateRate();
  }, [selectedItems]);

  const checkoutRow = (items: NFTData[]) => {
    const firstItem = items[0];
    return (
      <Row
        key={firstItem.assetContract}
        className="mb-3 align-items-center"
        style={{
          textAlign: "left",
        }}
      >
        <Col xs="auto">
          <Image
            roundedCircle
            src={firstItem.collectionImageUrl}
            alt=""
            style={{
              width: "80px",
            }}
          />
        </Col>
        <Col>
          <Row style={{ fontSize: "18px" }}>
            {items.length}x {firstItem.collectionName}
          </Row>
          <Row>Token IDs [{items.map((i) => `#${i.tokenId}`).join(", ")}]</Row>
        </Col>
      </Row>
    );
  };

  return (
    <Col
      md={5}
      style={{
        borderRadius: "24px",
        background: "white",
        boxShadow: "0px 0px 8px 0px rgba(0,0,0,.15)",
        padding: "24px 40px",
        height: "fit-content",
        position: "fixed",
        right: "0",
        marginRight: "32px",
      }}
    >
      <Row className="text-subheadline">Selected NFTs</Row>
      <Row id="checkout-items" className="mt-3 mb-3">
        {itemGroups && itemGroups.length > 0 ? (
          <ListGroup>{itemGroups.map((group) => checkoutRow(group))}</ListGroup>
        ) : (
          <Col className="mt-5 mb-5">No NFTs selected 😭</Col>
        )}
      </Row>
      <Row className="mt-2 mb-4 text-subheadline">
        <Col style={{ textAlign: "left" }}>You will receive</Col>
        <Col style={{ textAlign: "right", fontWeight: "500" }}>
          {estimatedLitAmount && selectedItems ? estimatedLitAmount : "---"} LIT
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={12} md={10}>
          {isSwapPending ? (
            <Spinner animation="border" style={{ color: Colors.blue }} />
          ) : (
            <div className="mb-2">
              {selectedItems.length > 1 && !isBatchApproved ? (
                <>
                <Button onClick={refreshApprovalStatus} variant="outline-info" size="sm">
                  Refresh Approval Status
                </Button>
                {" "}
                <Button onClick={onApprovalClick} variant="secondary" size="lg">
                  Set Approvals
                </Button>
                {" "}
                </>
              ) : (
                ""
              )}{" "}
              <Button onClick={onSwapClick} disabled={(selectedItems.length > 1) && !isBatchApproved} variant="primary" size="lg">
                Swap NFTs for $LIT
              </Button>
            </div>
          )}
        </Col>
      </Row>
      <Row className="mt-5 text-caption">Contract Addresses</Row>
      <Row className="text-caption">
        Firesale.art: {addresses.firesale_address}
      </Row>
      <Row className="text-caption">
        LIT Token: {addresses.lit_token_address}
      </Row>
    </Col>
  );
}
