import Colors from "../lib/colors"

type Props = {
  title: string
  color?: string
  backgroundColor?: string
  borderColor?: string
  fontSize?: string
  padding?: string
  onClick: () => any
}

export default function ButtonRound({
  title,
  color,
  backgroundColor,
  borderColor,
  fontSize,
  padding,
  onClick
}: Props) {

  const btnBgColor = backgroundColor ?? 'white'

  return (
    <div
      onClick={onClick}
      style={{
        cursor: 'pointer',
        borderRadius: '32px',
        color: color ?? 'black',
        backgroundColor: btnBgColor,
        border: `2px solid ${borderColor ?? btnBgColor}`,
        padding: padding ?? '4px 8px',
        fontSize: fontSize ?? '14px',
        boxShadow: '0px 0px 8px 0px rgba(0,0,0,.15)'
      }}
    >
      {title}
    </div>
  )
}
