import { Container, Row, Col } from "react-bootstrap"
import Flames from '../assets/flame.gif'

export default function Footer() {
  return (
    <Row
      style={{
        position: 'fixed',
        bottom: '0',
        width: '100vw',
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Col className="d-none d-md-block">
        <img src={Flames} alt=""/>
      </Col>
      <Col>
        “One man’s trash is another man’s treasure.”
        – Proverb
      </Col>
      <Col className="d-none d-md-block">
        <img src={Flames} alt=""/>
      </Col>
    </Row>
  )
}
