import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Colors from "../lib/colors";
import { isLive, VAULT_ADDRESS } from "../lib/constants";
import {
  Button,
  Card,
  Spinner,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import { VaultNftData } from "../types";
import ButtonRound from "../components/ButtonRound";
import OpenseaLogo from "../assets/OpenseaLogo";
import { getAccountNfts } from '../services/firesale';


export default function Vault() {
  const [vaultNfts, setVaultNfts] = useState<VaultNftData[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const limit = 50;

  useEffect(() => {
    getNfts();
  }, [offset]);

  const loadNextPage = () => {
    setOffset(offset + limit);
  };


  const getNfts = async () =>  {
    setIsLoading(true);
    let response = await getAccountNfts(VAULT_ADDRESS, offset, limit);
      const paginated = vaultNfts.concat(response);
        setTimeout(() => {
          setVaultNfts(paginated);
          setIsLoading(false);
        }, 3000);
      };

  return (
    <Container fluid>
      <Row
        className="justify-content-md-center"
        style={{ padding: "20px", marginTop: "24px" }}
      >
        {vaultNfts &&
          vaultNfts.map((nft: VaultNftData) => (
            <div style={{ margin: "5px" }} key={nft.id}>
              <Card
                key={nft.id}
                style={{
                  width: "10rem",
                  height: "100%",
                  border: "none",
                  boxShadow: "0px 0px 8px 0px rgba(0,0,0,.15)",
                  borderRadius: "16px",
                }}
              >
                <Card.Img
                  variant="top"
                  src={nft.imageUrl}
                  style={{ borderRadius: "16px" }}
                />
                <Card.Body>
                  <Card.Text>
                    <div className="text-caption one-line">
                      {nft.collectionName}
                    </div>
                    <div className="fs-text-body">#{nft.tokenId}</div>
                    <div className="text-caption" style={{ color: "green" }}>
                      Last Sale Price: {nft.formattedLastSale}
                    </div>
                    <a href={nft.openseaLink} target="_blank">
                    <OpenseaLogo className=""/>
                    </a>

                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          ))}
        {isLoading && (
          <div style={{ margin: "5px" }}>
            <Card
              style={{
                width: "10rem",
                height: "100%",
                border: "none",
                boxShadow: "0px 0px 8px 0px rgba(0,0,0,.15)",
                borderRadius: "16px",
              }}
            >
              <Card.Body>
                <Card.Text>
                  <>
                    <Button variant="primary" disabled>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Loading...
                    </Button>
                  </>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        )}
      </Row>
      <Row className="justify-content-md-center" style={{ padding: "45px" }}>
        <Col>
          <ButtonRound
            backgroundColor="blue"
            color="white"
            title="Load More"
            onClick={() => loadNextPage()}
          />
        </Col>
      </Row>
    </Container>
  );
}
