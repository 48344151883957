import { SERVER_URL } from "../lib/constants"
import axios from "axios"

export const getAccountNfts = async (accountAddress, offset, limit ) => {
  try {
    const response = await axios.get(`${SERVER_URL}/vaultNfts?address=${accountAddress}&offset=${offset}&limit=${limit}`)
    console.log(response.data)
    return response.data.paginated
  } catch (error) {
    console.log(error)
  }
}