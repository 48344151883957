import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Alert } from "react-bootstrap";

import { Ether, useEthers } from "@usedapp/core";
import erc721Abi from "../lib/contracts/testERC721.json";
import fireSaleAbi from "../lib/contracts/Firesale.json";
import litTokenAbi from "../lib/contracts/LITToken.json";
import { ethers } from "ethers";
import { isDevelopment } from "../lib/constants";
import { testWalletNfts } from "../lib/networks";
import { getWhitelist } from "../lib/parse";
import MerkleTree from "merkletreejs";
import { getProof } from "../lib/whitelistProof";
import keccak256 from "keccak256";

import Colors from "../lib/colors";
import ButtonRound from "../components/ButtonRound";
import CheckoutWidget from "../components/CheckoutWidget";
import { AddressBook, VaultNftData } from "../types";
import { getAccountNfts } from "../services/firesale";

global.Buffer = global.Buffer || require("buffer").Buffer;

type Props = {
  account?: string | null;
  addresses: AddressBook;
};

type ParseResult = {
  data: Array<Project>;
};

type Project = {
  CONTRACT_ADDRESS: string;
  PROJECT_NAME: string;
};

export default function Selection({ account, addresses }: Props) {
  const [nfts, setNfts] = useState<VaultNftData[]>([]);
  const [litBalance, setLitBalance] = useState("0");
  const [whitelistProjects, setWhitelistProjects] = useState<ParseResult>();
  const [whitelistTree, setWhitelistTree] = useState<MerkleTree>();
  const [selectedNFTs, setSelectedNFTs] = useState<VaultNftData[]>([]);
  const [isSwapPending, setIsSwapPending] = useState<boolean>(false);
  const [isBatchApproved, setIsBatchApproved] = useState<boolean>(false);
  const { library } = useEthers();

  // const testAccount = "0xf296178d553c8ec21a2fbd2c5dda8ca9ac905a00" // dhof mainnet
  const [offset, setOffset] = useState<number>(0);
  const limit = 50;

  useEffect(() => {
    const getNfts = async () => {
      if (account && whitelistProjects && !isDevelopment) {
        const fetchedNfts = await getAccountNfts(account, offset, limit);
        let contractAddresses = fetchedNfts.map((x) => x.assetContract);
        let relevantWhitelistedAddresses = whitelistProjects.data
          .filter((x) => contractAddresses.includes(x.CONTRACT_ADDRESS))
          .map((x) => x.CONTRACT_ADDRESS);
        let filteredData = fetchedNfts.filter((x) =>
          relevantWhitelistedAddresses.includes(x.assetContract)
        );

        const paginated = nfts.concat(filteredData);
        setNfts(paginated);
      } else if (isDevelopment) {
        setNfts(testWalletNfts);
      }
    };
    getNfts();
  }, [account, offset, whitelistProjects]);

  useEffect(() => {
    if (whitelistProjects) {
      let addresses = whitelistProjects.data.map((x) => x.CONTRACT_ADDRESS);
      let leaves = addresses.map((x) => keccak256(x));
      let draftTree = new MerkleTree(leaves, keccak256, {
        sort: true,
      });

      console.log("sort root", draftTree.getHexRoot());
      setWhitelistTree(draftTree);
    }
  }, [whitelistProjects]);

  useEffect(() => {
    updateBatchApprovalStatus();
  }, [selectedNFTs]);

  useEffect(() => {
    getWhitelist(setWhitelistProjects);
    const updateLitBalance = async () => {
      let balance = 0;

      const LITToken = new ethers.Contract(
        addresses.lit_token_address,
        litTokenAbi,
        library
      );
      balance = await LITToken.balanceOf(account);
      setLitBalance(balance.toString());
    };
    updateLitBalance();
  }, [account]);

  const prepareBatchData = () => {
    var groupedByProject: { [key: string]: number[] } = {};

    selectedNFTs.forEach((x: VaultNftData) => {
      let updatedGroup = groupedByProject[x.assetContract];

      if (updatedGroup) {
        updatedGroup.push(x.tokenId);
        groupedByProject[x.assetContract] = updatedGroup;
      } else {
        groupedByProject[x.assetContract] = [x.tokenId];
      }
    });
    return groupedByProject;
  };

  async function handleSwap() {
    console.log("Handle SWAP CALLED");
    setIsSwapPending(true);
    const signer = library?.getSigner();
    if (signer) {
      if (selectedNFTs.length === 0) {
        setIsSwapPending(false);
        return window.confirm("Select an NFT to swap!");
      }
      if (selectedNFTs.length === 1) {
        singleSwap(signer);
      } else if (selectedNFTs.length > 1) {
        batchSwap(signer);
      }
    }
  }

  const singleSwap = async (signer) => {
    let firesaleContract = new ethers.Contract(
      addresses.firesale_address,
      fireSaleAbi,
      library
    );

    let whitelistActive = await firesaleContract.whitelistActive();
    let singleNft = selectedNFTs[0];
    const nftContract = new ethers.Contract(
      singleNft.assetContract,
      erc721Abi,
      library
    );
    if (whitelistActive) {
      const leaf = keccak256(singleNft.assetContract);
      const proof = whitelistTree.getHexProof(leaf);
      let finalProof = "0x" + proof.join("").replaceAll("0x", "");

      let connectedContract = nftContract.connect(signer);
      await connectedContract[
        "safeTransferFrom(address,address,uint256,bytes)"
      ](account, addresses.firesale_address, singleNft.tokenId, finalProof);
    } else {
      let connectedContract = nftContract.connect(signer);
      await connectedContract["safeTransferFrom(address,address,uint256)"](
        account,
        addresses.firesale_address,
        singleNft.tokenId
      );
    }

    setIsSwapPending(false);
  };

  const updateBatchApprovalStatus = async () => {
    const signer = library?.getSigner();
    let groupedByProject = prepareBatchData();
    let txPayload = [];
    let allApproved = true;
    for (const [key, value] of Object.entries(groupedByProject)) {
      let nftContract = new ethers.Contract(key, erc721Abi, library);
      let connectedContract = nftContract.connect(signer);
      let bundle: [string, number[], Array<string>] = [key, [], []];
      txPayload.push(bundle);
      let isAlreadyApproved = await connectedContract.isApprovedForAll(
        account,
        addresses.firesale_address
      );
      if (!isAlreadyApproved) {
        allApproved = false;
      }
    }
    if (allApproved) {
      setIsBatchApproved(true);
    } else {
      setIsBatchApproved(false);
    }
  };

  const setApprovals = async () => {
    const signer = library?.getSigner();
    let groupedByProject = prepareBatchData();
    let txPayload = [];
    for (const [key, value] of Object.entries(groupedByProject)) {
      let nftContract = new ethers.Contract(key, erc721Abi, library);
      let connectedContract = nftContract.connect(signer);
      let bundle: [string, number[], Array<string>] = [key, [], []];
      txPayload.push(bundle);
      let isAlreadyApproved = await connectedContract.isApprovedForAll(
        account,
        addresses.firesale_address
      );
      if (!isAlreadyApproved) {
        connectedContract
          .setApprovalForAll(addresses.firesale_address, true)
          .then((data: any) => {
            console.log("approval success", data);
            setIsSwapPending(false);
          })
          .catch((error: any) => {
            console.log("approval error", error);
            setIsSwapPending(false);
          });
      }
    }
  };

  const batchSwap = async (signer) => {
    console.log("BATCH SWAP CALLED");
    let groupedByProject = prepareBatchData();
    let txPayload = [];
    for (const [key, value] of Object.entries(groupedByProject)) {
      let nftContract = new ethers.Contract(key, erc721Abi, library);
      let connectedContract = nftContract.connect(signer);
      let bundle: [string, number[], Array<string>] = [key, [], []];
      value.forEach((x) => bundle[1].push(x));
      let proof = getProof(key, whitelistTree);
      bundle[2] = proof;
      txPayload.push(bundle);
      let isAlreadyApproved = await connectedContract.isApprovedForAll(
        account,
        addresses.firesale_address
      );
      if (!isAlreadyApproved) {
        connectedContract
          .setApprovalForAll(addresses.firesale_address, true)
          .then((data: any) => {
            console.log("approval success", data);
            setIsSwapPending(false);
          })
          .catch((error: any) => {
            console.log("approval error", error);
            setIsSwapPending(false);
          });
      }

      // using .then vs await was leading to multiple txs getting created for some reason
    }
    let firesaleContract = new ethers.Contract(
      addresses.firesale_address,
      fireSaleAbi,
      library
    );

    let connectedFiresaleContract = firesaleContract.connect(signer);
    let tx = await connectedFiresaleContract.depositMultiERC721Batch(txPayload);
    await tx.wait();
    setIsSwapPending(false);
  };

  const handleUpdate = (nft: VaultNftData) => {
    if (nfts && nft) {
      let newSelectedNFTs = [...selectedNFTs];
      const index = nfts?.findIndex((obj) => obj.id === nft.id);
      if (nft.selected) {
        newSelectedNFTs = newSelectedNFTs.filter(
          (item: VaultNftData) => item.id !== nft.id
        );
      } else {
        newSelectedNFTs.push(nft);
      }
      setSelectedNFTs(newSelectedNFTs);
      const updatedNfts = nfts;
      updatedNfts[index] = { ...nft, selected: !nft.selected };
      setNfts(updatedNfts);
    }
  };

  const loadNextPage = () => {
    setOffset(offset + limit);
  };

  return (
    <Container
      fluid
      style={{
        padding: "0px 40px",
      }}
    >
      <Col id="items">
        <Row className="mt-4 align-items-center">
          <span className="text-headline">My Wallet</span>
        </Row>
        <Row className="mt-4">
          <Col
            style={{
              display: "flex",
              flexWrap: "wrap",
              padding: "10px",
            }}
            md={7}
          >
            {nfts.length > 0 ? (
              <>
                <div
                  className="justify-content-center mt-4 mb-4"
                  style={{ padding: "36px" }}
                >
                  <Alert style={{ fontSize: "12px" }} variant="info">
                    These are your NFTs that are part of our whitelisted
                    contracts. Due to API delays, the swapped NFT may show up in
                    this list for a few minutes.
                  </Alert>
                </div>
                <Row>
                  {nfts.map((nft: VaultNftData) => (
                    <div style={{ margin: "5px" }} key={nft.id}>
                      <Card
                        key={nft.id}
                        style={{
                          width: "10rem",
                          height: "100%",
                          border: "none",
                          boxShadow: "0px 0px 8px 0px rgba(0,0,0,.15)",
                          borderRadius: "16px",
                        }}
                      >
                        <Card.Img
                          variant="top"
                          src={nft.imageUrl}
                          style={{ borderRadius: "16px" }}
                        />
                        <Card.Body>
                          <Card.Text>
                            <div className="text-caption one-line">
                              {nft.collectionName}
                            </div>
                            <div className="fs-text-body">#{nft.tokenId}</div>
                            <div
                              className="text-caption"
                              style={{ color: "green" }}
                            >
                              Tax Saving: {nft.taxSavings}
                            </div>
                          </Card.Text>
                          <div style={{ alignItems: "end", marginTop: "auto" }}>
                            {selectedNFTs.map((n) => n.id).includes(nft.id) ? (
                              <ButtonRound
                                title="Remove"
                                color={Colors.orange}
                                borderColor={Colors.orange}
                                onClick={() => handleUpdate(nft)}
                              />
                            ) : (
                              <ButtonRound
                                title="Select"
                                color="white"
                                backgroundColor={Colors.orange}
                                onClick={() => handleUpdate(nft)}
                              />
                            )}
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                </Row>
                <Row className="mt-4 mb-4">
                  <Col>
                    <ButtonRound
                      title="Load More"
                      onClick={() => loadNextPage()}
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <h2>We did not find any nfts in this wallet 🔎</h2>
            )}
          </Col>
          <CheckoutWidget
            selectedItems={selectedNFTs}
            addresses={addresses}
            litBalance={litBalance}
            isSwapPending={isSwapPending}
            onSwapClick={handleSwap}
            refreshApprovalStatus={updateBatchApprovalStatus}
            onApprovalClick={setApprovals}
            isBatchApproved={isBatchApproved}
          />
        </Row>
      </Col>
    </Container>
  );
}
