import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Colors from '../lib/colors'

export default function About() {
    return (
        <Container fluid>
            <Col className='text-subheadline'>
                <div style={{marginTop: "10%"}}>
                    <Row className='About'>
                    <a href='https://etherscan.io/address/0xbBC61c3799C9CCB64493f9661901841DdB672D8e' style={{ color: Colors.red }}> Firesale </a> is the world's #1 gas-optimized tax harvesting tool for your illiquid jpegs
                    </Row>
                    <Row className='About'>
                        Each NFT deposited is converted into <a href='https://etherscan.io/address/0xBe0dD5C0fa2c291f1b1F990B6C8490C1ea864478' style={{ color: Colors.red }}>  $LIT  </a> following a <a href='https://www.desmos.com/calculator/3iwuckklf1' style={{ color: Colors.red }}>bonding curve</a> mechanism.
                    </Row>
                    <Row className='About'>
                        Future proceeds of deposited NFTs will be returned to <a href='https://etherscan.io/address/0xBe0dD5C0fa2c291f1b1F990B6C8490C1ea864478' style={{ color: Colors.red }}> $LIT </a> holders via a token buyback and burn mechanism.
                    </Row>
                    <Row className='About'>
                        Derivative projects based on the Firesale Art Collection will be purchasable with <a href='https://etherscan.io/address/0xBe0dD5C0fa2c291f1b1F990B6C8490C1ea864478' style={{ color: Colors.red }}> $LIT </a>. 
                    </Row>
                </div>
            </Col>
        </Container>
    );
  }
