
import SelectInput from '@material-ui/core/Select/SelectInput';
import { readRemoteFile, readString } from 'react-papaparse';
import csvData from "./whitelist.csv"

export const getWhitelist = async (callback) =>{

  fetch(csvData).then(rs=> rs.text()).then(text=>{
    readString(text, {
      worker: true,
      header: true,
      complete: (results) => {
        callback(results)
      }
    })
  })
}